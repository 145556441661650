
export default {
  methods: {
    startCountdown () {
      this.countdownSound.play()
      this.$refs.countdown.style.display = 'block'
      this.arenaVisible = true
      this.countdownRunning = true

      const timeline = this.$anime.timeline({
        easing: 'easeOutExpo',
        duration: 600,
        complete: () => {
          if (this.$refs.countdown) {
            this.$refs.countdown.style.display = 'none'
            this.countdownRunning = false
          }
        }
      });

      timeline
      .add({
        targets: '.counter--round, .counter--fight',
        opacity: 0,
        scale: 0.5,
        translateX: '0',
        translateY: '0'
      })
      .add({
        targets: '.counter--round',
        scale: 1,
        opacity: 0,
        translateY: "-2rem"
      })
      .add({
        targets: '.counter--round',
        opacity: 1,
        translateY: "0",
        duration: 1000
      })
      .add({
        targets: '.counter--fight',
        scale: 1,
        opacity: 1,
        duration: 1000
      })
      .add({
        targets: '.counter--round, .counter--fight',
        opacity: 0,
        scale: 5
      })
    },
  }
}
