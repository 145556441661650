import Vue from 'vue'
import App from './App.vue'
import store from "@/store"
import global from '@/plugins/global'
import i18n from '@/plugins/i18n'

Vue.mixin(global)

import '@/assets/sass/main.sass'

Vue.config.productionTip = false

new Vue({
  i18n,
  store,
  render: h => h(App),
}).$mount('#app')
