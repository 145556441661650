// Axios Config

var axios = require('axios')
// TODO: Outsource in dotenv => https://www.npmjs.com/package/dotenv

const username = process.env.VUE_APP_API_USERNAME
const password = process.env.VUE_APP_API_PASSWORD
const token = `${username}:${password}`
const encodedToken = Buffer.from(token).toString('base64')

var axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: { 'Authorization': `Basic ${encodedToken}` }
});

module.exports = axiosInstance
