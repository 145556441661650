export default {
  data () {
    return {
      shotsReceivedPlayer1: 0,
      shotsReceivedPlayer2: 0,
    }
  },
  methods: {
    shotForPlayer(player) {

      this.wonSound.play()

      const flyingShot = this.$refs.flyingShot
      let position

      if (player === 'top') {
        position = 1 + this.shotsReceivedPlayer1++
        flyingShot.classList.add("shot--rotated")
      } else {
        position = 1 + this.shotsReceivedPlayer2++
        flyingShot.classList.remove("shot--rotated")
      }

      const targetShot = this.$refs[`shot_${player}_${position}`]

      const parent = flyingShot.parentNode
      flyingShot.style.top = `${parent.offsetHeight / 2}px`
      flyingShot.style.left = `${parent.offsetWidth / 2}px`

      const destinationTop = targetShot.offsetTop
      const destinationLeft = targetShot.offsetLeft

      const timeline = this.$anime.timeline({
        easing: 'easeOutExpo',
        duration: 500,

        complete: function () {
          targetShot.classList.remove("shot--idle")
          flyingShot.style.opacity = 0
        }
      });

      timeline
      .add({
        targets: '.circle',
        opacity: 0
      })
      .add({
        targets: '.shot--flying',
        opacity: 1,
        height: '30%'
      })
      .add({
        targets: '.player-scored',
        delay: 100,
        opacity: 1
      })
      .add({
        targets: '.shot--flying',
        delay: 1000,
        opacity: 1,
        height: '8%',
        top: `${destinationTop}px`,
        left: `${destinationLeft}px`
      })
      .add({
        targets: '.player-scored',
        opacity: 0
      })
      .add({
        targets: '.circle',
        opacity: 1
      })
    },

    resetShots() {
      this.shotsReceivedPlayer1 = 0
      this.shotsReceivedPlayer2 = 0
      this.$refs['shot_top_1'].classList.add('shot--idle')
      this.$refs['shot_top_2'].classList.add('shot--idle')
      this.$refs['shot_bottom_1'].classList.add('shot--idle')
      this.$refs['shot_bottom_2'].classList.add('shot--idle')
    },
  }
}
