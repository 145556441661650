<template>
  <div class="wrapper">
    <slot />
  </div>
</template>

<script>
export default {
}
</script>

<style lang="sass" scoped>
.wrapper
  height: 100vh
  background: black

  @media (min-width: 430px)
    width: 400px
    margin: 0 auto
    position: relative
    // just for illustration
    border: 1px solid white

  @media (min-height: 850px)
    margin-top: 50px
    height: 800px
</style>
