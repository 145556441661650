<template>
  <div>
    <div class="header">
      <img src="@/assets/images/logo.png" class="logo-top" />
      <div>Ice Cold Competition</div>
      <img src="@/assets/images/powered_by_pocketrocket.png" class="powered-by" />
    </div>
    <div class="container">
      <div>
        <h1>Ranking</h1>
        <div>
          <div v-for="(data, index) in mappedData" :key="index" class="ranking">
            <div class="rank rank--position">{{ index + 1 }}</div>
            <div class="rank rank--name">{{ data.name.replaceAll("%20", " ") }}</div>
            <div class="rank rank--score">{{ data.count }}</div>
          </div>
        </div>
        
        <!-- <div class="pagination"> -->
          <!-- TODO: Build dynamic pagination -->
          <!-- <div class="page page--active">1–10</div>
          <div class="page">11–20</div>
          <div class="page">21–26</div>
        </div> -->
      </div>

      <div>
        <h1>Last matches</h1>
        <div v-for="(match, index) in lastMatches.slice(0, 3)" :key="index" class="match">
          <div>
            {{ match.name_player_one.replaceAll("%20", " ") }}
          </div>
          <div class="match__score">
            <div class="match__player-score">
              {{ match.score_player_one }}
            </div>
            <div class="match__divider">:</div>
            <div class="match__player-score">
              {{ match.score_player_two }}
            </div>
          </div>
          <div>{{ match.name_player_two.replaceAll("%20", " ") }}</div>
        </div>

        <h1 class="competition">Join the competition!</h1>
        <div class="competition_wrapper">
          <img src="@/assets/images/qrcode.png" class="qrcode" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const client = require('@/client')
export default {
  components: {},
  props: {},
  data () {
    return {
      mappedData: [],
      lastMatches: [],
      rawData: [],
      timer: ''
    }
  },
  created () {
    this.getRankingItems()
    this.timer = setInterval(this.getRankingItems, 60000);
  },
  methods: {
    async getRankingItems () {
      this.lastMatches = []
      try {
        const res = await client.get(`/public/items/competition_winners?per_page=10000&page=1&sort_by=created_at&sort_order=DESC`)
        this.rawData = res.data.resources
        console.log(this.rawData)
        if (res) {
          const jsonData = res.data.resources

          const mappedData = {};
          jsonData.forEach(obj => {
            console.log(obj)

            this.lastMatches.push(obj.data.match)

            const platformId = obj.platform_id;
            if (!mappedData[platformId]) {
              mappedData[platformId] = {
                ...obj,
                count: 1
              };
            } else {
              mappedData[platformId].count++;
            }
          });

          const uniqueObjects = Object.values(mappedData);
          this.mappedData = uniqueObjects.sort((a, b) => b.count - a.count);
        }
      } catch (error) {
        console.log(error)
      }
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
  },
  beforeDestroy() {
    this.cancelAutoUpdate();
  },
}
</script>

<style lang="sass" scoped>
@import "@/assets/sass/abstracts/variables"

.header
  position: fixed
  font-family: 'meister', sans-serif
  font-size: 2.0rem
  font-weight: bold
  letter-spacing: 0.12em
  line-height: 1.25
  text-transform: uppercase
  z-index: 10
  width: 100%
  padding: 2rem 50px
  display: flex
  align-items: center
  background: rgba(0, 0, 0, 0.3)

.logo-top
  width: 4rem
  margin-right: 3rem

.powered-by
  height: 4rem
  margin-left: auto

.container
  display: grid
  grid-template-columns: 1fr 1fr
  gap: 10rem
  background: #1d3222
  padding: 10rem 5rem

h1
  font-family: 'meister', sans-serif
  font-size: 3rem
  font-weight: 900
  letter-spacing: 0.12em
  line-height: 1.25
  text-transform: uppercase
  color: rgb(233, 227, 199)
  position: relative
  margin-bottom: 4rem

  &:after
    content: ''
    width: 5.3333em
    height: 100%
    min-height: 2.5em
    border-bottom: 0.5em solid rgb(221, 90, 18)
    position: absolute
    top: 0
    transform: translateY(-1.35em)
    z-index: -1
    left: -1rem

.ranking
  font-size: 4vh
  display: grid
  grid-template-columns: 8rem 1fr 10rem
  padding: 1rem
  border-top: 2px solid rgba(255, 255, 255, 0.5)

  &:last-of-type
    border-bottom: 2px solid rgba(255, 255, 255, 0.5)
  
  &:first-of-type
    background: rgba(255, 255, 255, 0.2)    

.rank
  padding: 0 1rem
  font-family: 'meister', sans-serif
  font-weight: bold

.rank--position
  text-align: center
  color: $color-orange
  border-right: 4px solid $color-orange

.rank--name
  padding-left: 3rem
  font-weight: 900
  
.rank--score
  padding: 0 1rem
  text-align: center
  background-color: rgba(255, 255, 255, 0.2)
  color: $color-cream
  border-radius: 1rem

.match
  font-family: 'meister', sans-serif
  font-weight: 900
  font-size: 4vh
  display: grid
  grid-template-columns: 1fr auto 1fr
  gap: 3rem
  height: 10rem
  border-top: 2px solid rgba(255, 255, 255, 0.5)
  align-items: center
  text-align: right

  > :nth-child(3)
    text-align: left

  &:last-of-type
    border-bottom: 2px solid rgba(255, 255, 255, 0.5)    

.match__score
  font-family: 'meister', sans-serif
  font-weight: 900
  text-align: center
  color: $color-orange
  background-color: rgba(255, 255, 255, 0.2)
  border-radius: 1rem
  display: grid
  grid-template-columns: 1fr auto 1fr
  padding: 0 2rem
  font-size: 6vh

.match__divider
  padding: 0 2rem
  
.pagination
  display: flex
  margin-top: 2rem
  margin-top: 5rem
  justify-content: center

  .page
    display: flex
    font-size: 3rem
    background-color: rgba(255, 255, 255, 0.2)
    align-items: center
    justify-content: center
    height: 5rem
    white-space: nowrap
    padding: 0 2rem
    margin: 0 0.5rem

  .page--active
    background-color: $color-orange

.qrcode
  height: 20vh
  width: 20vh

.competition
  margin-top: 5rem

.competition_wrapper
  display: flex
  justify-content: center
  align-items: center
</style>
