<template>
  <div class="prologue container">

    <video
      v-show="videoStarted"
      muted
      playsinline
      loop
      ref="introVideo"
      :class="['intro-video', { 'started' : prologueStep === 'intro'} ]"
    >
      <source src="@/assets/videos/intro.mp4" type="video/mp4">
      Your browser does not support HTML5 video.
    </video>

    <img class="intro-image" src="@/assets/images/intro-blurry.jpg" v-show="!videoStarted" />

    <div v-if="prologueStep === 'start'" class="h-full">
      <Start
        @setPrologueStep="setPrologueStep"
      />
    </div>

    <div v-if="prologueStep === 'intro'" class="h-full">
      <Intro />
    </div>

    <img class="fog" src="@/assets/images/fog.png" />
  </div>
</template>

<script>
import Start from '@/components/prologue/Start'
import Intro from '@/components/prologue/Intro'
export default {
  components: { Start, Intro },
  data () {
    return {
      prologueStep: 'start',
      videoStarted: false
    }
  },
  methods: {
    setPrologueStep (step) {
      // start the video
      if (step === 'intro') {
        this.videoStarted = true
        const video = this.$refs.introVideo
        video.play()
      }
      this.prologueStep = step
    }
  }
}
</script>

<style lang="sass" scoped>
.intro-video, .intro-image
  z-index: 1
  position: absolute
  right: 0
  top: 0
  width: 200%
  -webkit-transform-style: preserve-3d
  will-change: transform

  &.started
    animation: 1s ease-out 0s slideVideo forwards

.fog
  transform: translateX(-200rem)
  z-index: 2
  position: absolute
  bottom: -10rem
  animation: slideFog 50s 1s linear infinite
  will-change: transform
  left: 0
  transform: translateX(-100%)

@keyframes slideFog
  to
    transform: translateX(100%)

@keyframes slideVideo
  to
    transform: translateX(50%)

</style>
