<template>
  <div class="finish container">
    <video
      muted
      playsinline
      class="freeze-video"
      ref="freezeVideo"
    >
      <source src="@/assets/videos/freeze.mp4" type="video/mp4">
      Your browser does not support HTML5 video.
    </video>
    <div class="box">
      <div align="center" class="appear claims">
        <img src="@/assets/images/logo_jaegermeister.png" class="logo" />
        <div class="player-name is-text-orange is-uppercase is-text-bold is-size-large">{{ lost }}</div>
        <img :src="winnerImage" class="winning-claim" />
        <img src="@/assets/images/drink_responsibly.svg" class="drink-responsibly" />
      </div>

      <div class="shots">
        <div class="move-in-shot left">
          <img :src="glassImage" class="shot" />
        </div>
        <div class="move-in-shot right">
          <img :src="glassImage" class="shot" />
        </div>
      </div>

      <div class="bottom-set">
        <div class="button move-in-from-bottom" @click="goToChooseName()">
          {{ $t('finish.cta') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const client = require('@/client')
export default {
  props: {
    lost: {
      type: String
    },
    won: {
      type: String
    },
    namePlayerOne: {
      type: String
    },
    namePlayerTwo: {
      type: String
    },
    scorePlayerOne: {
      type: Number
    },
    scorePlayerTwo: {
      type: Number
    },
    finish: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    glassImage () {
      const glassColor = this.$store.state.glassColor
      return require(`@/assets/images/ice_cold_shot_${glassColor}.png`)
    },
    winnerImage () {
      const lang = this.$store.state.language
      return require(`@/assets/images/winner_text_${lang}.svg`)
    }
  },
  methods: {
    goToChooseName () {
      this.clickSound.play()
      this.currentStep = 'chooseName'
    },
    goToEnding () {
      this.clickSound.play()
      this.prologueStep = 'intro'
      this.currentStep = 'prologue'
    },
    async submitWinnerNameToApi () {
      try {
        const res = await client.post(`/public/items/add_competition_winner`,
          { winner_name: this.won, match: { "name_player_one": this.namePlayerOne, "score_player_one": this.scorePlayerOne, "name_player_two": this.namePlayerTwo, "score_player_two": this.scorePlayerTwo } }
        )
        if (res) {
          console.log(res)
        }
      } catch (error) {
        console.log(error)
      }
    }
  },
  watch: {
    finish () {
      if (this.finish) {
        this.freezeSound.play()
        const video = this.$refs.freezeVideo
        video.play()
        this.submitWinnerNameToApi()
      }
    }
  }
}
</script>

<style lang="sass" scoped>
@import "@/assets/sass/abstracts/variables"
.finish
  position: relative
  .freeze-video
    z-index: 1
    object-fit: fill
    height: 100%
    width: 100%

  .claims
    margin-top: -1rem
    height: 50%

    .logo
      height: 20%
      width: auto

  .appear
    opacity: 0
    animation: appear .5s ease-in forwards 1s

  .winning-claim
    height: 40%
    display: block

  .drink-responsibly
    height: 2%
    margin-top: 1rem
    display: block

  .shots
    position: absolute
    left: 0
    right: 0
    bottom: 15rem
    height: 30%
    text-align: center

    .move-in-shot
      position: relative
      display: inline-block
      height: 100%
      transform: translateX(100rem)
      margin: -2rem
      &.left
        animation: moveInRight 1.5s ease-in forwards 1.5s
      &.right
        animation: moveInRight 1.5s ease-in forwards 2s
      .shot
        height: 100%

  .move-in-from-bottom
    transform: translateY(70rem)
    animation: moveInFromBottom 2s ease-in forwards 2s

@keyframes moveInRight
  0%
    opacity: 0

  100%
    opacity: 1
    transform: translateX(0rem)

@keyframes moveInFromBottom
  0%
    opacity: 0

  100%
    opacity: 1
    transform: translateY(0rem)

@keyframes appear
  0%
    opacity: 0
  100%
    opacity: 1
</style>
