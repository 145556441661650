<template>
  <div :class="['player-model', position]">
    <div class="player-animation-wrapper">
      <div class="player--torso">
        <div :class="['player--torso-detail', `clicks--${totalClicks % 12}`]" />
      </div>
      <div class="player--head">
        <div :class="['player--head-detail', `clicks--${totalClicks % 5}`]" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    position: String,
    totalClicks: Number
  },
  methods: {
  }
}
</script>

<style lang="sass" scoped>
$scaleFactorTorso: 48
$widthTorso: calc(650rem/#{$scaleFactorTorso})
$heightTorso: calc(1500rem/#{$scaleFactorTorso})
$relativePositionTorso: -50%

$scaleFactorHead: 4.3
$widthHead: calc(130rem/#{$scaleFactorHead})
$heightHead: calc(112rem/#{$scaleFactorHead})

.bottom
  transform: translate(-100%, -200%) rotate(180deg)

.player-animation-wrapper
  animation: meetingStags 1s 0s normal forwards
  transform: translateY(-50px)
  opacity: 0

.player--torso
  width: $widthTorso
  height: $heightTorso
  transform: translate($relativePositionTorso, $relativePositionTorso * 2.4)

  &-detail
    height: 100%
    background-repeat: no-repeat
    background-size: cover
    background-image: url("~@/assets/sprites/deer-torso.png")
    @for $i from 1 through 12
      &.clicks--#{$i}
        background-position-x: calc(#{$widthTorso} * -#{$i})

.player--head
  width: $widthHead
  height: $heightHead
  transform: translate($relativePositionTorso, $relativePositionTorso * 4.05)
  &-detail
    height: 100%
    background-repeat: no-repeat
    background-size: cover
    background-image: url("~@/assets/sprites/deer-head.png")
    animation-name: moveHead
    animation-duration: 1s
    animation-timing-function: steps(5)
    animation-fill-mode: forwards
    transition: transform 0.2s ease-out
    @for $i from 1 through 5
      &.clicks--#{$i}
        transform: rotate(#{($i - 3) * 3}deg)
        
@keyframes moveHead
  to
    background-position-x: calc(#{$widthHead} * -5)

@keyframes meetingStags
  0%
    transform: translateY(-50px)
    opacity: 0
  50%
    opacity: 1
  100%
    transform: translateY(0px)
    opacity: 1

</style>
