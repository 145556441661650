<template>
  <div class="prologue-start">
    <div align="center"><img src="@/assets/images/logo_jaegermeister.png" class="logo" /></div>
    <div class="header is-size-large is-text-bold is-uppercase">
      <div>{{ $t('prologue.start.who_pays') }}</div>
      <div class="stripe" />
      <div>{{ $t('prologue.start.the_next') }}</div>
      <div>{{ $t('prologue.start.round') }}</div>
      <div class="subline is-size-small">to win a Jägermeister Soundboks!</div>
    </div>

    <div class="move-in-shot">
      <img :src="glassImage" class="shot" />
    </div>

    <img src="@/assets/images/minus_18.png" class="minus-18" />

    <div class="bottom-set">
      <div class="button" @click="emitSetPrologueStep('intro')">
        {{ $t('prologue.start.cta') }}
      </div>
      <div align="center"><img src="@/assets/images/powered_by_pocketrocket.png" class="powered_by" /></div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    glassImage () {
      const glassColor = this.$store.state.glassColor
      return require(`@/assets/images/ice_cold_shot_${glassColor}.png`)
    },
  },
  methods: {
    playBackgroundSound () {
      this.backgroundSound.volume = 0.3
      this.backgroundSound.loop = true
      this.backgroundSound.play()
    },
    emitSetPrologueStep (step) {
      this.clickSound.play()
      this.$emit('setPrologueStep', step)
    }
  },
  watch: {
    currentStep () {
      if (this.currentStep === 'prologue') {
        this.playBackgroundSound()
      }
    }
  }
}
</script>

<style lang="sass" scoped>
@import "@/assets/sass/abstracts/variables"

.prologue-start
  position: relative
  z-index: 10
  height: 100%
  padding: 0 2rem

.stripe
  height: 12px
  background-color: $color-orange
  width: 80%
  margin-left: -3rem
  margin-top: -2rem
  margin-bottom: 1rem

.subline
  margin-top: 2rem

.move-in-shot
  text-align: center
  animation: moveInRight 2s ease-in forwards
  position: absolute
  width: 100%
  left: 0
  right: 0
  bottom: 9rem

  .shot
    height: 35rem

.minus-18
  position: absolute
  bottom: 28rem
  right: 14%
  width: 19rem
  opacity: 0
  scale: 2
  animation: fadeInScale .5s ease-in 2.5s 1 normal forwards

.powered_by
  width: 60%
  margin-top: 1rem

@keyframes moveInRight
  0%
    opacity: 0
    transform: translateX(100rem)

  100%
    opacity: 1
    transform: none

@keyframes fadeInScale
  0%
    opacity: 0
    scale: 2

  100%
    opacity: 1
    scale: 1
</style>
